angular.module('fingerink')
    .directive('avanzadaCheck', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-check.tpl.html',
            scope: {
                data: '=',
                fillable: '=',
                heightPx: '=',
                widthPx: '='
            },
            controllerAs: 'controller',
            controller: 'avanzadaCheckCtrl'
        };
    })
    .controller('avanzadaCheckCtrl', function ($rootScope, $scope) {
        var that = this;

        $scope.click = function () {
            if ($scope.fillable) {
                $scope.data.answer = !$scope.data.answer;
            }
        };




    });
